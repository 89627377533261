/* eslint-disable react-hooks/exhaustive-deps */
import { AccessTime } from '@mui/icons-material';
import { IconButton, ModalTitle } from './styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  Typography,
  useTheme,
  Button,
  ButtonGroup
} from '@mui/material';
import { LinearProgressBar } from 'components/Stepper/style';
import {
  addMinutes,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  minutesToSeconds
} from 'date-fns';
import { CodeTabContent } from './fragments/code-tab-content';
import { CustomButton } from './fragments/custom-button';
import { QRTabContent } from './fragments/qr-tab-content';

export type PixTabs = 'code' | 'qr';

type ModalProps = {
  open: boolean;
  pixCode: string;
  onClose: () => void;
  createdAt: string;
  price: string;
  pixCodeImage: string;
};

import { useEffect, useState } from 'react';

export default function PixInAppModal(props: ModalProps) {
  const { onClose, open, pixCode, createdAt, price, pixCodeImage } = props;
  const theme = useTheme();
  const orderCreatedAt = createdAtToDate();
  const [today, setToday] = useState<Date>(new Date());
  const difference = differenceInMinutes(today, orderCreatedAt);
  const timeLimit = 10;
  const progress = (difference / timeLimit) * 100;
  const progressInSeconds =
    (differenceInSeconds(today, orderCreatedAt) / minutesToSeconds(timeLimit)) *
    100;
  const isCompleted = progress >= 100;
  const secondsLeft = minutesToSeconds(10 - difference);
  const endDate = addMinutes(orderCreatedAt, 10);
  const timeDifferenceInMilliseconds = differenceInMilliseconds(endDate, today);
  const minutes = Math.floor(timeDifferenceInMilliseconds / 60000);
  const seconds = Math.floor((timeDifferenceInMilliseconds % 60000) / 1000);
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedTimeLeft = `${formattedMinutes}:${formattedSeconds}`;
  const [tabSelected, setTabSelected] = useState<PixTabs>('code');

  useEffect(() => {
    setInterval(() => setToday(new Date()), 1000);
  }, []);

  function createdAtToDate() {
    const splitedDateAndHour = createdAt.split(' ');
    const splitedDate = splitedDateAndHour[0].split('/');
    const splitedHour = splitedDateAndHour[1].split(':');

    return new Date(
      +splitedDate[2],
      +splitedDate[1] - 1,
      +splitedDate[0],
      +splitedHour[0],
      +splitedHour[1],
      0
    );
  }

  if (secondsLeft <= 0) {
    return (
      <>
        <Dialog
          open={open}
          maxWidth="sm"
          fullWidth={true}
          onClose={onClose}
          sx={{ padding: '24px' }}
        >
          <DialogTitle>
            <ModalTitle>O tempo do pagamento pix encerrou</ModalTitle>
            <IconButton aria-label="close-modal" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Stack direction={'column'} width={'100%'} gap={'16px'}>
              <Typography
                fontWeight={'400'}
                fontSize={'14px'}
                color={theme.palette.grey[600]}
              >
                {`Caso já tenha efetuado o pagamento, selecione "Já efetuei o
                pagamento" e aguardar a aprovação (a aprovação pode levar até 5
                minutos).`}
              </Typography>
              <Stack direction={'row'} gap={'16px'} justifyContent={'flex-end'}>
                <Button variant="contained" onClick={onClose}>
                  Já efetuei o pagamento
                </Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        maxWidth="sm"
        fullWidth={true}
        onClose={onClose}
        sx={{ padding: '24px' }}
      >
        <DialogTitle>
          <ModalTitle>Pagamento com pix</ModalTitle>
          <IconButton aria-label="close-modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Stack direction={'column'} width={'100%'} gap={'24px'}>
            <Stack
              direction={'column'}
              width={'100%'}
              border={`solid 1px ${theme.palette.grey[300]}`}
              borderRadius={'8px'}
            >
              <Stack
                direction={'row'}
                p={'12px 16px'}
                justifyContent={'space-between'}
                bgcolor={theme.palette.grey[50]}
                borderBottom={`solid 1px ${theme.palette.grey[300]}`}
              >
                <Typography variant="body1" fontWeight={'700'}>
                  Total a pagar
                </Typography>
                <Typography variant="body1" fontWeight={'700'}>
                  {price}
                </Typography>
              </Stack>
              <Stack direction={'column'} p={'12px 16px'} gap={'8px'}>
                <Typography
                  variant="body2"
                  fontWeight={'400'}
                  color={theme.palette.grey[600]}
                >
                  Tempo restante para efetuar pagamento
                </Typography>
                <Stack direction={'row'} gap={'4px'} alignItems={'center'}>
                  <AccessTime
                    sx={{
                      color: theme.palette.primary.xxdark,
                      fontSize: '20px'
                    }}
                  />
                  <Typography
                    variant="h6"
                    fontWeight={'600'}
                    color={theme.palette.primary.xxdark}
                  >
                    {formattedTimeLeft}
                  </Typography>
                </Stack>
                <LinearProgressBar
                  variant="determinate"
                  value={isCompleted ? 100 : progressInSeconds}
                  sx={{
                    backgroundColor: theme.palette.grey[200],
                    height: '8px'
                  }}
                />
                <Typography
                  variant="caption"
                  fontWeight={'400'}
                  fontSize={'12px'}
                  lineHeight={'15px'}
                  color={theme.palette.grey[600]}
                >
                  Efetue o pagamento em até 10 minutos, após esse tempo o código
                  abaixo será anulado.
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'column'} gap={'8px'}>
              <Typography fontSize={'16px'} fontWeight={'600'}>
                Como você gostaria de pagar:
              </Typography>
              <Stack direction={'row'} width={'100%'}>
                <ButtonGroup variant="outlined" fullWidth>
                  <CustomButton
                    label="Via Código"
                    tab="code"
                    setSelectedTab={setTabSelected}
                    selectedTab={tabSelected}
                  />
                  <CustomButton
                    label="Via QR Code"
                    tab="qr"
                    setSelectedTab={setTabSelected}
                    selectedTab={tabSelected}
                  />
                </ButtonGroup>
              </Stack>
            </Stack>
            {tabSelected == 'code' && <CodeTabContent pixCode={pixCode} />}
            {tabSelected == 'qr' && (
              <QRTabContent pixCodeImage={pixCodeImage} />
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}
